@import "module";

#__next {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  margin: auto;
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  &>main {
    font-size: 18px;
    color: #1B1B1B;
    background-color: #F2F3F5;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  a {
    color: inherit;
    text-decoration: none;
  }
}

h1,
.h1 {
  margin-bottom: $spacer * 1.5;
  font-family: var(#{--bs-font-family-h1});
  font-weight: var(#{--bs-font-weight-h1});
  font-size: 56px;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 48px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
  }

  @media (max-width: 576px) {}
}

h2,
.h2 {
  font-family: var(#{--bs-font-family-h2});
  font-weight: var(#{--bs-font-weight-h2});
  margin: 0;
  font-size: 56px;

  @media (max-width: 992px) {
    font-size: 48px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
  }

  @media (max-width: 576px) {}
}

h3,
.h3 {
  font-family: var(#{--bs-font-family-h3});
  font-weight: var(#{--bs-font-weight-h3});
  margin: 0;
}

h4,
.h4 {
  font-family: var(#{--bs-font-family-h4});
  font-weight: var(#{--bs-font-weight-h4});
  font-size: 34px;
  margin: 0;

  @media (max-width: 992px) {}

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 576px) {}
}

h5,
.h5 {
  font-family: var(#{--bs-font-family-h5});
  font-weight: var(#{--bs-font-weight-h5});
  font-size: 24px;
  margin: 0;
  color: var(#{--bs-tertiary});

  @media (max-width: 992px) {}

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 576px) {}
}

h6,
.h6 {
  font-family: var(#{--bs-font-family-h6});
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}

p {
  font-size: 16px;
  line-height: 30px;
}

.subtitle1 {
  font-family: var(#{--bs-font-family});
  font-size: 16px;
  font-weight: 600;
  line-height: 1.30;
  color: #3E3C3A;
}

.subtitle2 {
  font-family: var(#{--bs-font-family});
  font-size: 14px;
  font-weight: 700;
  line-height: 1.21;
  color: #3E3C3A;
}

.body1 {
  font-family: var(#{--bs-font-family});
  font-size: 16px;
  font-weight: 300;
  line-height: 2;
  color: var(#{--bs-tertiary});
}

.body2 {
  font-family: var(#{--bs-font-family});
  font-size: 14px;
  font-weight: 400;
  line-height: 1.21;
  color: var(#{--bs-tertiary});
}

.caption {
  font-family: var(#{--bs-font-family});
  font-size: 12px;
  font-weight: 700;
  line-height: 1.18;
  color: #757575;
}

.overline {
  font-family: var(#{--bs-font-family});
  font-size: 14px;
  font-weight: 900;
  line-height: 1.20;
  color: #006075;
}

body {
  font-family: var(#{--bs-font-family});
}

.overlay {
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  &.dark {
    opacity: 0.7;
  }

  &.primary {
    background-color: var(#{--bs-primary});
  }

  &.secondary {
    background-color: var(#{--bs-secondary});
  }

  &.tertiary {
    background-color: var(#{--bs-tertiary});
  }

  &.quaternary {
    background-color: var(#{--bs-quaternary});
  }

  &.yellow {
    background-color: var(#{--bs-yellow});
  }

  &.green {
    background-color: var(#{--bs-green});
  }

  &.blue {
    background-color: var(#{--bs-blue});
  }

  &.red {
    background-color: var(#{--bs-red});
  }
}

.tag {
  &.primary {
    color: var(#{--bs-primary});
    background-color: rgba(var(#{--bs-primary-rgb}), 0.2)
  }

  &.secondary {
    color: var(#{--bs-secondary});
    background-color: rgba(var(#{--bs-secondary-rgb}), 0.2)
  }

  &.tertiary {
    color: var(#{--bs-tertiary});
    background-color: rgba(var(#{--bs-tertiary-rgb}), 0.2)
  }

  &.quaternary {
    color: var(#{--bs-quaternary});
    background-color: rgba(var(#{--bs-quaternary-rgb}), 0.2)
  }

  &.yellow {
    color: var(#{--bs-yellow});
    background-color: rgba(var(#{--bs-yellow-rgb}), 0.2)
  }

  &.green {
    color: var(#{--bs-green});
    background-color: rgba(var(#{--bs-green-rgb}), 0.2)
  }

  &.blue {
    color: var(#{--bs-blue});
    background-color: rgba(var(#{--bs-blue-rgb}), 0.2)
  }

  &.red {
    color: var(#{--bs-red});
    background-color: rgba(var(#{--bs-red-rgb}), 0.2)
  }
}

.highlight-span {
  &.primary {
    color: var(#{--bs-primary});
  }

  &.secondary {
    color: var(#{--bs-secondary});
  }

  &.tertiary {
    color: var(#{--bs-tertiary});
  }

  &.quaternary {
    color: var(#{--bs-quaternary});
  }

  &.yellow {
    color: var(#{--bs-yellow});
  }

  &.green {
    color: var(#{--bs-green});
  }

  &.blue {
    color: var(#{--bs-blue});
  }

  &.red {
    color: var(#{--bs-red});
  }
}

.fill {
  &.primary {
    fill: var(#{--bs-primary});
  }

  &.secondary {
    fill: var(#{--bs-secondary});
  }

  &.tertiary {
    fill: var(#{--bs-tertiary});
  }

  &.quaternary {
    fill: var(#{--bs-quaternary});
  }

  &.yellow {
    fill: var(#{--bs-yellow});
  }

  &.green {
    fill: var(#{--bs-green});
  }

  &.blue {
    fill: var(#{--bs-blue});
  }

  &.red {
    fill: var(#{--bs-red});
  }
}

.stroke {
  &.primary {
    stroke: var(#{--bs-primary});
  }

  &.secondary {
    stroke: var(#{--bs-secondary});
  }

  &.tertiary {
    stroke: var(#{--bs-tertiary});
  }

  &.quaternary {
    stroke: var(#{--bs-quaternary});
  }

  &.yellow {
    stroke: var(#{--bs-yellow});
  }

  &.green {
    stroke: var(#{--bs-green});
  }

  &.blue {
    stroke: var(#{--bs-blue});
  }

  &.red {
    stroke: var(#{--bs-red});
  }
}

.bg {
  &.primary {
    background-color: var(#{--bs-primary});
  }

  &.secondary {
    background-color: var(#{--bs-secondary});
  }

  &.tertiary {
    background-color: var(#{--bs-tertiary});
  }

  &.quaternary {
    background-color: var(#{--bs-quaternary});
  }

  &.yellow {
    background-color: var(#{--bs-yellow});
  }

  &.green {
    background-color: var(#{--bs-green});
  }

  &.blue {
    background-color: var(#{--bs-blue});
  }

  &.red {
    background-color: var(#{--bs-red});
  }
}

button {
  background-color: transparent;

  &.btn-primary {
    border: solid 1px var(#{--bs-primary});
    color: var(#{--bs-primary});

    &:hover {
      background-color: var(#{--bs-primary});
      color: white;

      svg {
        fill: white;
      }
    }
  }

  &.btn-primary-fill {
    background-color: var(#{--bs-primary});
    color: white;

    &:hover {
      border: 1px solid var(#{--bs-primary});
      background-color: transparent;
      color: var(#{--bs-primary});

      svg {
        fill: white;
      }
    }
  }

  &.btn-secondary {
    border: solid 1px var(#{--bs-secondary});
    color: var(#{--bs-secondary});

    &:hover {
      background-color: var(#{--bs-secondary});
      color: white;

      svg {
        fill: white;
      }
    }
  }

  &.btn-tertiary {
    border: solid 1px var(#{--bs-tertiary});
    color: var(#{--bs-tertiary});

    &:hover {
      background-color: var(#{--bs-tertiary});
      color: white;

      svg {
        fill: white;
      }
    }
  }

  &.btn-quaternary {
    border: solid 1px var(#{--bs-quaternary});
    color: var(#{--bs-quaternary});

    &:hover {
      background-color: var(#{--bs-quaternary});
      color: white;

      svg {
        fill: white;
      }
    }
  }

  &.btn-yellow {
    border: solid 1px var(#{--bs-yellow});
    color: var(#{--bs-yellow});

    &:hover {
      background-color: var(#{--bs-yellow});
      color: white;

      svg {
        fill: white;
      }
    }
  }

  &.btn-green {
    border: solid 1px var(#{--bs-green});
    color: var(#{--bs-green});

    &:hover {
      background-color: var(#{--bs-green});
      color: white;

      svg {
        fill: white;
      }
    }
  }

  &.btn-blue {
    border: solid 1px var(#{--bs-blue});
    color: var(#{--bs-blue});

    &:hover {
      background-color: var(#{--bs-blue});
      color: white;

      svg {
        fill: white;
      }
    }
  }

  &.btn-red {
    border: solid 1px var(#{--bs-red});
    color: var(#{--bs-red});

    &:hover {
      background-color: var(#{--bs-red});
      color: white;

      svg {
        fill: white;
      }
    }
  }

  &.btn-black {
    color: white;
    background-color: black;

    &:hover {
      background-color: rgb(40, 40, 40);
    }
  }
}

.button2 {
  width: 100%;
  display: flex;
  transition: $default-transition;
  justify-content: space-between;
  border: solid 1px transparent;
  color: white;

  &:hover {
    padding-left: 15%;
    transition: $default-transition;
  }

  &.bg-primary,
  &.bg-primary:hover {
    background-color: var(#{--bs-primary});
  }

  &.bg-secondary,
  &.bg-secondary:hover {
    background-color: var(#{--bs-secondary});
  }

  &.bg-tertiary,
  &.bg-tertiary:hover {
    background-color: var(#{--bs-tertiary});
  }

  &.bg-quaternary,
  &.bg-quaternary:hover {
    background-color: var(#{--bs-quaternary});
  }

  &.bg-yellow,
  &.bg-yellow:hover {
    background-color: var(#{--bs-yellow});
  }

  &.bg-green,
  &.bg-green:hover {
    background-color: var(#{--bs-green});
  }

  &.bg-blue,
  &.bg-blue:hover {
    background-color: var(#{--bs-blue});
  }

  &.bg-red,
  &.bg-red:hover {
    background-color: var(#{--bs-red});
  }

  &.outline-primary,
  &.outline-primary:hover {
    border: solid 1px var(#{--bs-primary});
    color: var(#{--bs-primary});
  }

  &.outline-secondary,
  &.outline-secondary:hover {
    border: solid 1px var(#{--bs-secondary});
    color: var(#{--bs-secondary});
  }

  &.outline-tertiary,
  &.outline-tertiary:hover {
    border: solid 1px var(#{--bs-tertiary});
    color: var(#{--bs-tertiary});
  }

  &.outline-quaternary,
  &.outline-quaternary:hover {
    border: solid 1px var(#{--bs-quaternary});
    color: var(#{--bs-quaternary});
  }

  &.outline-yellow,
  &.outline-yellow:hover {
    border: solid 1px var(#{--bs-yellow});
    color: var(#{--bs-yellow});
  }

  &.outline-green,
  &.outline-green:hover {
    border: solid 1px var(#{--bs-green});
    color: var(#{--bs-green});
  }

  &.outline-blue,
  &.outline-blue:hover {
    border: solid 1px var(#{--bs-blue});
    color: var(#{--bs-blue});
  }

  &.outline-red,
  &.outline-red:hover {
    border: solid 1px var(#{--bs-red});
    color: var(#{--bs-red});
  }
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: all 200ms ease-in;

  &.primary {
    background: linear-gradient(to bottom, rgba(var(#{--bs-primary-rgb}), 0.2), var(#{--bs-primary}));
  }

  &.secondary {
    background: linear-gradient(to bottom, rgba(var(#{--bs-secondary-rgb}), 0.2), var(#{--bs-secondary}));
  }

  &.tertiary {
    background: linear-gradient(to bottom, rgba(var(#{--bs-tertiary-rgb}), 0.2), var(#{--bs-tertiary}));
  }

  &.quaternary {
    background: linear-gradient(to bottom, rgba(var(#{--bs-quaternary-rgb}), 0.2), var(#{--bs-quaternary}));
  }

  &.yellow {
    background: linear-gradient(to bottom, rgba(var(#{--bs-yellow-rgb}), 0.2), var(#{--bs-yellow}));
  }

  &.green {
    background: linear-gradient(to bottom, rgba(var(#{--bs-green-rgb}), 0.2), var(#{--bs-green}));
  }

  &.blue {
    background: linear-gradient(to bottom, rgba(var(#{--bs-blue-rgb}), 0.2), var(#{--bs-blue}));
  }

  &.red {
    background: linear-gradient(to bottom, rgba(var(#{--bs-red-rgb}), 0.2), var(#{--bs-red}));
  }
}

.container:hover {
  * {
    transition: all 200ms ease-in;
  }

  .gradient-overlay {
    &.primary {
      background: linear-gradient(to bottom, rgba(var(#{--bs-primary-rgb}), 0.7), var(#{--bs-primary}));
    }

    &.secondary {
      background: linear-gradient(to bottom, rgba(var(#{--bs-secondary-rgb}), 0.7), var(#{--bs-secondary}));
    }

    &.tertiary {
      background: linear-gradient(to bottom, rgba(var(#{--bs-tertiary-rgb}), 0.7), var(#{--bs-tertiary}));
    }

    &.quaternary {
      background: linear-gradient(to bottom, rgba(var(#{--bs-quaternary-rgb}), 0.7), var(#{--bs-quaternary}));
    }

    &.yellow {
      background: linear-gradient(to bottom, rgba(var(#{--bs-yellow-rgb}), 0.7), var(#{--bs-yellow}));
    }

    &.green {
      background: linear-gradient(to bottom, rgba(var(#{--bs-green-rgb}), 0.7), var(#{--bs-green}));
    }

    &.blue {
      background: linear-gradient(to bottom, rgba(var(#{--bs-blue-rgb}), 0.7), var(#{--bs-blue}));
    }

    &.red {
      background: linear-gradient(to bottom, rgba(var(#{--bs-red-rgb}), 0.7), var(#{--bs-red}));
    }
  }
}

.text-white {
  color: white;
}

.ytp-impression-link {
  display: none !important;
}

.mw-100 {
  max-width: 100%;
}

.h-100 {
  height: 100%;
}