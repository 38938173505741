@import "src/scss/module";

.nav_cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  align-items: center;
  display: flex;
  z-index: -1;
  flex-direction: column;
  padding-top: 20vh;

  .link {
    text-transform: uppercase;
    position: relative;
    min-height: 48px;

    .link_text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      text-decoration-line: underline;
      text-decoration-thickness: 3px;
    }

    .dropdown {
      padding-left: 24px;
    }

    a {
      text-decoration: none;
      color: #3E3C3A;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
    }

    .subCont {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100%);
      transition: visibility 0s, opacity 0.5s linear;
      height: 0;

      .list {
        margin-top: 5px;
        background-color: white;
        padding: 10px;
        border-radius: 10px;

        .sublink {
          padding: 12px 18px;
          border-radius: 24px;
          text-transform: initial;

          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 24px;
          }

          svg {
            fill: #757575;
          }

          &:hover {
            background-color: #F4F4F4;
          }
        }
      }
    }

    &.active {
      transition: $default-transition;
      height: 180px;

      .subCont {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: visibility 0s, opacity 0.5s linear;
      }
    }
  }
}

@media (max-width: 992px) {
  .nav_cont {
    gap: 2vw;
  }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {}