@import "src/scss/module";

.header {
  height: 55px;
  width: calc(80vw);
  position: fixed;
  top: 24px;
  left: 10vw;
  transition: $default-transition;
  z-index: 9;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border-radius: 30px;
    transition: $default-transition;
    background-color: white;

    .logo {
      cursor: pointer;

      .pattern {
        width: 148px;
        height: 45px;
      }
    }

    .toggle {
      display: none;
      cursor: pointer;
    }

    .links {
      display: flex;
      align-items: center;
      gap: 2vw;

      .contact {
        display: flex;
        align-items: center;
        background-color: black;
        text-decoration: none;
        color: white;
        padding: 12px 21px;
        border-radius: 24px;
        gap: 12px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        border: solid 1px white;
        transition: $default-transition;

        &:hover {
          background-color: var(#{--bs-primary-active});
          transition: $default-transition;
        }
      }
    }
  }

  &.scrolled {
    padding: 12px 2vw;
    transition: $default-transition;
    .container {
      background-color: var(#{--bs-primary});
      padding: 0 3vw;
      transition: $default-transition;
    }
  }
}

@media (max-width: 992px) {
  .header {
    width: 94vw;
    left: 3vw;
  }
}

@media (max-width: 800px) {
  .header {
    .container {
      .toggle {
        display: block;
      }
      .links {
        display: none;
      }
    }
  
    &.scrolled {
      padding: 12px 1vw;
      .container {
        padding: 0 2vw;
      }
    }
  }
}

@media (max-width: 576px) {
  .header {
    .container {
      .links {
        gap: 12px;
  
        .link {
          width: 40px;
          height: 40px;
          padding: 0;

          .desc {
            display: none;
          }

          svg {
            transform: scale(1);
            margin: auto;
          }
        }
      }
    }
  
    &.scrolled {
      padding: 12px 2vw;
      .container {
        padding: 0 7vw;
      }
    }
  }
}
