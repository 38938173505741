$gray-400: #b0b0b0;
$gray-300: #d6d6d6;
$gray-600: #6f6f6f;
$white: #FFFFFF;
$black: #000000;
$font-weight-semibold: 500;
$danger: red;
$spacer: 12px;
$container-padding-x: 2rem;
$transition-base: all 0.2s ease-in;
$hover-text: #5f9dab;