@import "src/scss/module";
@import "src/scss/variables";

$brand-color: var(#{--bs-primary});
$brand-bg: var(#{--bs-secondary});
$danger-color: var(#{--bs-tertiary});
$text-color: $white;
$spacer: 1rem;
$font-size: small;
$z-index: 1099;
$overlay-color: rgba($black, .75);

.cookieConsent {
  display: flex;
  position: fixed;
  gap: $spacer;
  color: $text-color;
  background-color: $danger-color;
  font-size: $font-size;
  z-index: $z-index;
  padding: 12px;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    background-color: $overlay-color;
    z-index: $z-index;
  }

  &__button {
    color: $white;
    background-color: $brand-color;
    font-size: $font-size;
    white-space: nowrap;
    margin: 0;
    border: 0;
    border-radius: 5px;
    padding: .5em 1em;

    &Wrapper {
      display: flex;
      gap: $spacer;
    }

    &Decline {
      color: $white;
      background-color: $brand-bg;
      font-size: $font-size;
      white-space: nowrap;
      margin: 0;
      border: 0;
      border-radius: 5px;
      padding: .5em 1em
    }
  }
}