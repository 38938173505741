@import "src/scss/module";

.nav_cont {
  display: flex;
  gap: 4vw;
  height: 55px;

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: 100%;
    border-bottom: solid 3px transparent;
    position: relative;

    a {
      text-decoration: none;
      color: #3E3C3A;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      font-size: 13px;
    }

    .subCont {
      position: absolute;
      top: 55px;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s, opacity 0.5s linear;

      .list {
        margin-top: 5px;
        background-color: white;
        padding: 10px 0;
        border-radius: 10px;

        .sublink {
          padding: 12px 18px;
          border-radius: 24px;
          text-transform: initial;

          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;

            .sublink_text {
              flex-grow: 1;
            }

            .icon {
              width: 16px;
            }
          }

          svg {
            fill: #757575;
          }

          &:hover {
            background-color: #F4F4F4;
          }
        }
      }
    }

    &:hover {
      border-bottom: solid 3px #3E3C3A;
      transition: $default-transition;

      .subCont {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity 0.5s linear;
      }
    }
  }
}

@media (max-width: 1200px) {
  .nav_cont {
    gap: 2vw;
  }
}

@media (max-width: 1050px) {
  .nav_cont {
    gap: 1vw;
  }
}

@media (max-width: 992px) {
  .nav_cont {
    gap: 2vw;
  }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {}